const commonControls = {
    settings: [{
        type: 'opacity'
    }]
};
const opacityOptions = {
    style: {
        opacity: 0.75
    }
};

export const layerButton = (id: string, title) => ({
    id,
    title,
    value: id
});

export const radarButton = {
    title: 'Radar',
    controls: commonControls,
    id: 'radar',
    value: 'radar',
    options: opacityOptions
};

export const radarGlobalButton = {
    id: 'radar-global',
    title: 'Radar',
    value: 'radar-global',
    controls: commonControls,
    options: opacityOptions
};

export const fradarButton = {
    title: 'Radar',
    controls: commonControls,
    id: 'fradar',
    value: 'fradar',
    options: opacityOptions
};

export const satelliteButton = {
    id: 'satellite',
    title: 'Satellite',
    controls: commonControls,
    segments: [{
        title: 'Infrared',
        value: 'satellite',
        options: {
            style: {
                opacity: 0.75
            }
        }
    },
    {
        title: 'Geocolor',
        value: 'satellite-geocolor,states-outlines-dk'
    }]
};

export const alertsButton = {
    id: 'alerts-custom',
    title: 'Alerts',
    value: 'alerts',
    controls: commonControls,
    options: opacityOptions
};

export const temperaturesButton = {
    id: 'temperatures',
    title: 'Temperatures',
    value: 'temperatures,states-outlines-dk',
    controls: commonControls
};

export const windsButton = {
    id: 'winds',
    title: 'Winds',
    value: 'wind-speeds:75,states-outlines-dk,wind-speeds-text-dk',
    controls: commonControls
};

export const dewPointButton = {
    id: 'dewPoint',
    title: 'Dew Point',
    value: 'dew-point',
    controls: commonControls
};

export const feelsLikeButton = {
    id: 'feelsLike',
    title: 'Feels Like',
    value: 'feels-like',
    controls: commonControls
};

export const snowDepthButton = {
    id: 'snow-depth',
    title: 'Snow Depth',
    value: 'snow-depth',
    controls: commonControls,
    options: opacityOptions
};

export const stormCellsSegments = {
    id: 'stormcells',
    title: 'Storm Cells',
    multiselect: true,
    segments: [{
        title: 'Hail',
        value: 'stormcells-hail'
    },
    {
        title: 'Rotating',
        value: 'stormcells-rotating'
    },
    {
        title: 'Tornadic',
        value: 'stormcells-tornadic'
    },
    {
        title: 'General',
        value: 'stormcells-general'
    }]
};

// {
//   title: 'Storm Cells',
//   value: 'stormcells'
// },
// {
//   id: 'lightning',
//   title: 'Lightning',
//   value: 'lightning-strikes',
//   controls: commonControls
// },
// {
//   id: 'tropical',
//   title: 'Tropical Systems',
//   value: 'tropical-cyclones'
// },

export const fvisibilityButton = {
    id: 'fvisibility',
    title: 'Visibility',
    value: 'fvisibility',
    controls: commonControls,
    options: {
        style: {
            opacity: 0.75
        },
        alwaysShow: true
    }
};

export const visibilityButton = {
    id: 'visibility',
    title: 'Visibility',
    value: 'visibility',
    controls: commonControls,
    options: opacityOptions
};

export const convectiveButton = {
    id: 'convective',
    title: 'Convective',
    value: 'convective',
    controls: commonControls,
    options: opacityOptions
};

export const fjetstreamButton = {
    id: 'fjet-stream',
    title: 'Jet Stream',
    value: 'fjet-stream',
    controls: commonControls,
    options: {
        style: {
            opacity: 0.75
        },
        alwaysShow: true
    }
};

export const heatIndexButton = {
    id: 'heatIndex',
    title: 'Heat Index',
    value: 'heat-index',
    controls: commonControls,
    options: opacityOptions
};

export const humidityButton = {
    id: 'humidity',
    title: 'Humidity',
    value: 'humidity',
    controls: commonControls,
    options: opacityOptions
};

export const airQualitySegments = {
    id: 'air-quality',
    title: 'Air Quality',
    controls: {
        settings: [{
            type: 'opacity'
        }]
    },
    options: opacityOptions,
    segments: [{
        id: 'air-quality-index',
        title: 'AQI',
        value: 'air-quality-index'
    }, {
        id: 'air-quality-index-categories',
        title: 'AQI: Categories',
        value: 'air-quality-index-categories'
    }, {
        id: 'air-quality-pm2p5',
        title: 'Particle Pollution (PM2.5)',
        value: 'air-quality-pm2p5'
    }, {
        id: 'air-quality-pm10',
        title: 'Particle Pollution (PM10)',
        value: 'air-quality-pm10'
    }, {
        id: 'air-quality-no',
        title: 'Nitrogen Monoxide (NO)',
        value: 'air-quality-no'
    }, {
        id: 'air-quality-no2',
        title: 'Nitrogen Dioxide (NO2)',
        value: 'air-quality-no2'
    }, {
        id: 'air-quality-co',
        title: 'Carbon Monoxide (CO)',
        value: 'air-quality-co'
    }, {
        id: 'air-quality-so2',
        title: 'Sulfur Dioxide (SO2)',
        value: 'air-quality-so2'
    }, {
        id: 'air-quality-o3',
        title: 'Ozone (O3)',
        value: 'air-quality-o3'
    }]
};

export const sstButton = {
    title: 'Sea Surface Temps',
    value: 'maritime-sst',
    controls: commonControls,
    options: opacityOptions
};

export const currentsButton = {
    title: 'Ocean Currents',
    value: 'maritime-currents',
    controls: commonControls,
    options: opacityOptions
};

export const stormSurgeButton = {
    title: 'Storm Surge',
    value: 'maritime-surges',
    controls: commonControls,
    options: opacityOptions
};

export const tideHeightsButton = {
    title: 'Tide Heights',
    value: 'maritime-tides',
    controls: commonControls,
    options: opacityOptions
};

export const wavesSegments = {
    title: 'Waves',
    value: 'waves',
    controls: commonControls,
    options: opacityOptions,
    segments: [{
        title: 'Wave Heights', value: 'maritime-wave-heights'
    },
    { title: 'Wave Periods', value: 'maritime-wave-periods' }]
};

export const swell1Segments = {
    title: 'Swell 1',
    value: 'swell-1',
    controls: commonControls,
    options: opacityOptions,
    segments: [{
        title: 'Swell Heights', value: 'maritime-swell-heights'
    }, {
        title: 'Swell Periods', value: 'maritime-swell-periods'
    }]
};

export const swell2Segments = {
    title: 'Swell 2',
    value: 'swell-2',
    controls: commonControls,
    options: opacityOptions,
    segments: [{
        title: 'Swell Heights', value: 'maritime-swell-2-heights'
    }, {
        title: 'Swell Periods', value: 'maritime-swell-2-periods'
    }]
};

export const swell3Segments = {
    title: 'Swell 3',
    value: 'swell-3',
    controls: commonControls,
    options: opacityOptions,
    segments: [{
        title: 'Swell Heights', value: 'maritime-swell-3-heights'
    }, {
        title: 'Swell Periods', value: 'maritime-swell-3-periods'
    }]
};
