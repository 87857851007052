export const lightningStyle = {
    type: 'symbol',
    source: 'lightning',
    paint: {
        symbol: {
            shader: `
                #extension GL_OES_standard_derivatives : enable

                precision mediump float;

                uniform vec2 resolution;
                uniform float dpr;
                uniform float time;

                varying vec2 vUv;
                varying vec2 vPosition;
                varying float vFactor;
                varying float vRandom;

                float rand(float x) {
                return fract(sin(x)*75154.32912);
                }

                float rand3d(vec3 x) {
                return fract(375.10297 * sin(dot(x, vec3(103.0139,227.0595,31.05914))));
                }

                float noise(float x) {
                float i = floor(x);
                float a = rand(i), b = rand(i+1.);
                float f = x - i;
                return mix(a,b,f);
                }

                float perlin(float x) {
                float r=0.,s=1.,w=1.;
                for (int i=0; i<6; i++) {
                s *= 2.0;
                w *= 0.5;
                r += w * noise(s*x);
                }
                return r;
                }

                float noise3d(vec3 x) {
                vec3 i = floor(x);
                float i000 = rand3d(i+vec3(0.,0.,0.)), i001 = rand3d(i+vec3(0.,0.,1.));
                float i010 = rand3d(i+vec3(0.,1.,0.)), i011 = rand3d(i+vec3(0.,1.,1.));
                float i100 = rand3d(i+vec3(1.,0.,0.)), i101 = rand3d(i+vec3(1.,0.,1.));
                float i110 = rand3d(i+vec3(1.,1.,0.)), i111 = rand3d(i+vec3(1.,1.,1.));
                vec3 f = x - i;
                return mix(mix(mix(i000,i001,f.z), mix(i010,i011,f.z), f.y),
                    mix(mix(i100,i101,f.z), mix(i110,i111,f.z), f.y), f.x);
                }

                float perlin3d(vec3 x)
                {
                float r = 0.0;
                float w = 1.0, s = 1.0;
                for (int i=0; i<5; i++) {
                w *= 0.5;
                s *= 2.0;
                r += w * noise3d(s * x);
                }
                return r;
                }



                #define COL1 vec4(0, 0, 0, 0) / 255.0
                #define COL2 vec4(235, 241, 245, 255) / 255.0

                #define SIZE 100
                #define FLASH_POWER .8
                #define RADIUS .01
                #define SPEED .0018
                #define SEED 

                void main() {
                    vec2 pos = vUv;

                    float dist = length(2.0 * pos - 1.0) * 2.0;

                    float x = time + 0.1;

                    float m = 0.2 + 0.2 * vFactor; // max duration of strike
                    float i = floor(x/m);
                    float f = x/m - i;
                    float k = vFactor; // frequency of strikes
                    float n = noise(i);
                    float t = ceil(n-k); // occurrence
                    float d = max(0., n-k) / (1.-k); // duration
                    float o = ceil(t - f - (1. - d)); // occurrence with duration

                    float fx = 4.;
                    if (o == 1.) {
                        fx += 10. * vFactor;
                    }

                    fx = max(4., fx);
                    float g = fx / (dist * (10. + 20.)) * FLASH_POWER; 

                    float edgeFadeFactor = smoothstep(0.6, 1.0, dist);
                    float invertedEdgeFadeFactor = 1.0 - edgeFadeFactor;

                    vec4 color = mix(COL1, COL2, g);
                    color.a *= min(1.0, 0.5 + vFactor) * invertedEdgeFadeFactor;

                    gl_FragColor = color;
                    gl_FragColor.rgb *= gl_FragColor.a;
                }`,
            size: { width: 60, height: 60 },
            animated: true,
            blending: 2,
            pitchWithMap: true,
            factor: (data: any) => {
                const max = 200;
                return (200 - data.age) / 200;
            }
        }
    }
};

export const FtoCUnits = (f) => f * 0.555;

export const CtoFUnits = (c) => c * 1.8;

export const defaultOverrides = {
    animation: {
        interval: 12,
        clamp: 'past'
    }
};

export const futureOverrides = {
    animation: {
        interval: 12,
        clamp: 'future'
    }
};

export const opacityOptions = {
    paint: {
        sample: {
            opacity: 0.5
        }
    }
};

export const droughtMonitorButtonGL = {
    id: 'drought-monitor',
    title: 'Drought Monitor',
    value: 'drought-monitor',
    options: {
        paint: {
            fill: {
                opacity: 0.75
            }
        }
    }
};

export const temperaturesButtonGL = {
    id: 'temperatures',
    title: 'Temperatures',
    value: 'temperatures',
    options: {
        paint: {
            sample: {
                opacity: 0.5
            }
        }
    }
};

export const alertsButtonGL = {
    id: 'alerts-custom',
    title: 'Alerts',
    value: 'alerts',
    options: {
        paint: {
            fill: {
                opacity: 0.75
            }
        }
    }
};

export const radarButtonGL = {
    id: 'radar',
    title: 'Radar',
    value: 'radar',
    options: {
        paint: {
            sample: {
                opacity: 0.5
            }
        }
    }
};

export const snowDepthButtonGL = {
    id: 'snow-depth',
    title: 'Snow Depth',
    value: 'snow-depth',
    options: {
        paint: {
            sample: {
                opacity: 0.5
            }
        }
    }
};

export const fradarButtonGL = {
    id: 'fradar',
    title: 'Radar',
    value: 'fradar',
    options: {
        paint: {
            sample: {
                opacity: 0.5
            }
        }
    }
}

export const lightningInfo = {
    data: {
        evaluator: {
            title: 'Lightning',
            fn: (value) => {
                if (value.length > 0) {
                    return 'In the Area!';
                }
                return 'No strikes';
            }
        }
    }
};

export const airQualityIndexButtonGL = {
    id: 'air-quality-index',
    title: 'Air Quality',
    value: 'air-quality-aqi',
    options: opacityOptions
};

export const airQualitySegmentsGL = {
    id: 'air-quality',
    title: 'Air Quality',
    options: opacityOptions,
    filter: true,
    segments: [{
        id: 'air-quality-aqi',
        title: 'AQI',
        value: 'air-quality-aqi'
    }, {
        id: 'air-quality-aqi-categories',
        title: 'AQI: Categories',
        value: 'air-quality-aqi-categories'
    }, {
        id: 'air-quality-pm2p5',
        title: 'Particle Pollution (PM2.5)',
        value: 'air-quality-pm2p5'
    }, {
        id: 'air-quality-pm10',
        title: 'Particle Pollution (PM10)',
        value: 'air-quality-pm10'
    }, {
        id: 'air-quality-no',
        title: 'Nitrogen Monoxide (NO)',
        value: 'air-quality-no'
    }, {
        id: 'air-quality-no2',
        title: 'Nitrogen Dioxide (NO2)',
        value: 'air-quality-no2'
    }, {
        id: 'air-quality-co',
        title: 'Carbon Monoxide (CO)',
        value: 'air-quality-co'
    }, {
        id: 'air-quality-so2',
        title: 'Sulfur Dioxide (SO2)',
        value: 'air-quality-so2'
    }, {
        id: 'air-quality-o3',
        title: 'Ozone (O3)',
        value: 'air-quality-o3'
    }]
};

export const sstButtonGL = {
    title: 'Sea Surface Temps',
    value: 'sst',
    options: opacityOptions
};

export const currentsButtonGL = {
    title: 'Ocean Currents',
    value: 'currents',
    options: opacityOptions,
    segments: [{
        title: 'Currents: Fill', value: 'ocean-currents'
    },
    {
        title: 'Currents: Particles', value: 'ocean-currents-particles'
    }]
};

export const wavesSegmentsGL = {
    id: 'waves',
    title: 'Waves',
    value: 'waves',
    options: opacityOptions,
    segments: [{
        title: 'Wave Heights', value: 'wave-heights'
    },
    {
        title: 'Wave Periods', value: 'wave-periods'
    },
    {
        title: 'Wave Dir: Grid', value: 'wave-dir'
    },
    {
        title: 'Wave Dir: Particles', value: 'wave-particles'
    }]
};

export const swell1SegmentsGL = {
    title: 'Swell 1',
    value: 'swell',
    options: opacityOptions,
    segments: [{
        title: 'Swell Heights', value: 'wave-heights'
    }, { title: 'Swell Periods', value: 'swell-periods' }, {
        title: 'Swell Dir: Grid', value: 'swell-dir'
    }, {
        title: 'Swell Dir: Particles', value: 'swell-particles'
    }]
};

export const swell2SegmentsGL = {
    title: 'Swell 2',
    value: 'swell2',
    options: opacityOptions,
    segments: [{
        title: 'Swell Heights', value: 'swell2-heights'
    },
    { title: 'Swell Periods', value: 'swell2-periods' },
    {
        title: 'Swell Dir: Grid', value: 'swell2-dir'
    },
    {
        title: 'Swell Dir: Particles', value: 'swell2-particles'
    }]
};

export const swell3SegmentsGL = {
    title: 'Swell 3',
    value: 'swell3',
    options: opacityOptions,
    segments: [{
        title: 'Swell Heights', value: 'swell3-heights'
    },
    { title: 'Swell Periods', value: 'swell3-periods' },
    {
        title: 'Swell Dir: Grid', value: 'swell3-dir'
    },
    {
        title: 'Swell Dir: Particles', value: 'swell3-particles'
    }]
};

export const stormSurgeButtonGL = { title: 'Storm Surge', value: 'storm-surge', options: opacityOptions };

export const tideHeightsButtonGL = { title: 'Tide Heights', value: 'tide-heights', options: opacityOptions };

// const config = {
//     layers: [{
//         divider: true,
//         title: 'Conditions'
//     }, {
//         id: 'temps',
//         title: 'Temperatures',
//         value: [{
//             id: 'wx::temperatures',
//             config: {
//                 ...defaultOverrides
//             }
//         }],
//         settings: [
//             {
//                 key: 'color.scale',
//                 overrides: {
//                     scales: ['theme1.temps', 'theme2.temps', 'theme3.temps', 'general']
//                 }
//             }, {
//                 key: 'color.interval',
//                 overrides: {
//                     intervals: [0, 1, 2, 5, 10],
//                     converter: (v) => FtoCUnits(v),
//                     formatter: (v) => `${v}&deg;F`
//                 }
//             },
//             'data.quality'
//         ]
//     }, {
//         id: 'temps-feelslike',
//         title: 'Feels Like',
//         value: [{
//             id: 'wx::feels-like',
//             config: {
//                 ...defaultOverrides
//             }
//         }]
//     }, {
//         id: 'heat-index',
//         title: 'Heat Index',
//         value: [{
//             id: 'wx::heat-index',
//             config: {
//                 ...defaultOverrides
//             }
//         }],
//         settings: [
//             {
//                 key: 'color.scale',
//                 overrides: {
//                     scales: ['theme1.temps', 'theme2.temps', 'theme3.temps', 'general']
//                 }
//             }, {
//                 key: 'color.interval',
//                 overrides: {
//                     intervals: [0, 1, 2, 5, 10],
//                     converter: (v) => FtoCUnits(v),
//                     formatter: (v) => `${v}&deg;F`
//                 }
//             },
//             'data.interpolation',
//             'data.quality'
//         ]
//     }, {
//         id: 'humidity',
//         title: 'Humidity',
//         value: [{
//             id: 'wx::humidity',
//             config: {
//                 ...defaultOverrides
//             }
//         }],
//         settings: [
//             {
//                 key: 'color.scale',
//                 overrides: {
//                     scales: ['general']
//                 }
//             }, {
//                 key: 'color.interval',
//                 overrides: {
//                     intervals: [0, 1, 2, 5, 10],
//                     formatter: (v) => `${v}%`
//                 }
//             },
//             'data.quality'
//         ]
//     }, {
//         id: 'winds-particles',
//         title: 'Winds: Particles',
//         value: [{
//             id: 'wx::wind-particles',
//             config: {
//                 ...defaultOverrides,
//                 data: {
//                     quality: 'normal'
//                 },
//                 paint: {
//                     sample: {
//                         smoothing: 1,
//                         colorscale: {
//                             normalized: true,
//                             stops: [
//                                 0, '#ffffff',
//                                 1, '#ffffff'
//                             ]
//                         }
//                     },
//                     particle: {
//                         size: 1,
//                         count: 64 ** 2
//                     }
//                 }
//             }
//         }],
//         settings: [
//             {
//                 key: 'color.scale',
//                 overrides: {
//                     scales: ['theme1.winds', 'theme2.winds', 'theme3.winds', 'general', 'monochrome']
//                 }
//             },
//             'data.smoothing',
//             'data.quality',
//             'particle.speed',
//             'particle.size',
//             'particle.density'
//         ]
//     }, {
//         id: 'radar',
//         title: 'Radar',
//         value: [{
//             id: 'wx::radar',
//             config: {
//                 ...defaultOverrides
//             }
//         }],
//         settings: [
//             {
//                 key: 'color.scale',
//                 overrides: {
//                     scales: ['theme8.radar', 'theme1.radar', 'theme4.radar', 'theme5.radar', 'theme7.radar', 'general']
//                 }
//             }, {
//                 key: 'color.interval',
//                 overrides: {
//                     intervals: [0, 1, 2, 5, 10],
//                     formatter: (v) => `${v} dbz`
//                 }
//             },
//             'data.quality'
//         ]
//     }, {
//         id: 'satellite-raster',
//         title: 'Satellite: Raster',
//         value: ['satellite-raster']
//     },

//     {
//         divider: true,
//         title: 'Forecast'
//     }, {
//         id: 'ftemps',
//         title: 'Temperatures',
//         value: [{
//             id: 'wx::ftemperatures',
//             config: {
//                 ...futureOverrides
//             }
//         }],
//         settings: [
//             {
//                 key: 'color.scale',
//                 overrides: {
//                     scales: ['theme1.temps', 'theme2.temps', 'theme3.temps', 'general']
//                 }
//             }, {
//                 key: 'color.interval',
//                 overrides: {
//                     intervals: [0, 1, 2, 5, 10],
//                     converter: (v) => FtoCUnits(v),
//                     formatter: (v) => `${v}&deg;F`
//                 }
//             },
//             'data.quality'
//         ]
//     }, {
//         id: 'ftemps-feelslike',
//         title: 'Feels Like',
//         value: [{
//             id: 'wx::ffeels-like',
//             config: {
//                 ...futureOverrides
//             }
//         }]
//     }, {
//         id: 'fheat-index',
//         title: 'Heat Index',
//         value: [{
//             id: 'wx::fheat-index',
//             config: {
//                 ...defaultOverrides
//             }
//         }],
//         settings: [
//             {
//                 key: 'color.scale',
//                 overrides: {
//                     scales: ['theme1.temps', 'theme2.temps', 'theme3.temps', 'general']
//                 }
//             }, {
//                 key: 'color.interval',
//                 overrides: {
//                     intervals: [0, 1, 2, 5, 10],
//                     converter: (v) => FtoCUnits(v),
//                     formatter: (v) => `${v}&deg;F`
//                 }
//             },
//             'data.interpolation',
//             'data.quality'
//         ]
//     }, {
//         id: 'fhumidity',
//         title: 'Humidity',
//         value: [{
//             id: 'wx::fhumidity',
//             config: {
//                 ...futureOverrides
//             }
//         }],
//         settings: [
//             {
//                 key: 'color.scale',
//                 overrides: {
//                     scales: ['general']
//                 }
//             }, {
//                 key: 'color.interval',
//                 overrides: {
//                     intervals: [0, 1, 2, 5, 10],
//                     formatter: (v) => `${v}%`
//                 }
//             },
//             'data.quality'
//         ]
//     }, {
//         id: 'fwinds-particles',
//         title: 'Winds: Particles',
//         value: [{
//             id: 'wx::fwind-particles',
//             config: {
//                 ...futureOverrides,
//                 data: {
//                     quality: 'normal'
//                 },
//                 paint: {
//                     sample: {
//                         smoothing: 1,
//                         colorscale: {
//                             normalized: true,
//                             stops: [
//                                 0, '#ffffff',
//                                 1, '#ffffff'
//                             ]
//                         }
//                     },
//                     particle: {
//                         size: 1,
//                         count: 64 ** 2
//                     }
//                 }
//             }
//         }],
//         settings: [
//             {
//                 key: 'color.scale',
//                 overrides: {
//                     scales: ['theme1.winds', 'theme2.winds', 'theme3.winds', 'general', 'monochrome']
//                 }
//             },
//             'data.smoothing',
//             'data.quality',
//             'particle.speed',
//             'particle.size',
//             'particle.density'
//         ]
//     }, {
//         id: 'fradar',
//         title: 'Radar',
//         value: [{
//             id: 'wx::fradar',
//             config: {
//                 ...futureOverrides
//             }
//         }],
//         settings: [
//             {
//                 key: 'color.scale',
//                 overrides: {
//                     scales: ['theme8.radar', 'theme1.radar', 'theme4.radar', 'theme5.radar', 'theme7.radar', 'general']
//                 }
//             }, {
//                 key: 'color.interval',
//                 overrides: {
//                     intervals: [0, 1, 2, 5, 10],
//                     formatter: (v) => `${v} dbz`
//                 }
//             },
//             'data.quality'
//         ]
//     }, {
//         divider: true,
//         title: 'Severe'
//     }, {
//         id: 'alerts',
//         title: 'Alerts',
//         value: ['wx::alerts']
//     }, {
//         id: 'stormcells',
//         title: 'Storm Cells',
//         value: [{
//             id: 'wx::stormcells',
//             config: {
//                 data: {
//                     evaluator: {
//                         title: 'Storm Cells',
//                         fn: (value) => {
//                             if (value.length === 1) {
//                                 console.log(value);
//                                 console.log(value.length);
//                                 console.log(value[0].traits.type);
//                                 // return `${title(value[0].traits.type)} storm approaching!`;
//                             }

//                             if (value.length > 1) {
//                                 console.log(value);
//                                 return `${value.length} storms approaching!`;
//                             }
//                             return 'No storms';
//                         }
//                     }
//                 }
//             }
//         }]
//     }, {
//         id: 'stormreports',
//         title: 'Storm Reports',
//         value: ['wx::stormreports']
//     }, {
//         id: 'lightning',
//         title: 'Lightning',
//         value: [{
//             id: 'wx::lightning-strikes',
//             config: {
//                 ...lightningInfo
//             }
//         }]
//     }, {
//         id: 'lightning-heat',
//         title: 'Lightning: Heatmap',
//         value: [{
//             id: 'wx::lightning-strike-density',
//             config: {
//                 ...lightningInfo
//             }
//         }]
//     },
//     {
//         id: 'lightning-custom',
//         title: 'Lightning: Custom',
//         value: [{
//             id: 'wx::lightning-strikes',
//             config: {
//                 ...lightningInfo,
//                 ...LIGHTNING_STYLE,
//                 legend: {
//                     enabled: false
//                 }
//             }
//         }]
//     }, {
//         divider: true,
//         title: 'Other'
//     }, {
//         id: 'earthquakes-heat',
//         title: 'Earthquakes: Heatmap',
//         value: ['wx::earthquakes-heat']
//     }, {
//         id: 'fires',
//         title: 'Fires',
//         value: ['wx::fires-obs']
//     }]
// };
